import React from 'react';
import Menu from '../components/Menu';
// import Menu2 from '../components/Menu2';
import {graphql, Link} from 'gatsby';
import Page from '../components/Page';
import Sponsor from '../components/Sponsor';
import Logo from '../components/Logo';
import GamesButton from '../components/GamesButton';
import BookButton from '../components/BookButton';
import MusicButton from '../components/MusicButton';
import ParentsButton from '../components/ParentsButton';
import Seo from "../components/seo";


export default function Index({data: {images, global}}) {
	return <Page page="home-page" orientation="portrait">

		<Seo remoteMetaData={global} title={global.titleSEO} />

		<div className="header-area">
			<Menu/>
			{/*<Menu2/>*/}
		</div>


		<div className="game-box">
			<div className="game-box-inner">
				<div className="content">
					<div className="gen-page nav-grid position-absolute">
						<div className="logo-box"></div>
						<div className="button-box">
							<a href="https://keen-kids.square.site" target="_blank" className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>
					<div className="logo-avatar-combo">
						<Link to="/">
							<Logo flipped className="logo"/>
						</Link>

						<img src={images.octopus.localFile.publicURL} alt="Octopus avatar" className="avatar-octopus"/>
					</div>
					<Link to="/parents" className="kk-btn btn-wide a-center">
						<ParentsButton className="nav-button"/>
					</Link>
					<Link to="/game" className="kk-btn btn-wide a-center">
						<GamesButton className="nav-button"/>
					</Link>
					<Link to="/books" className="kk-btn btn-wide a-center">
						<BookButton className="nav-button"/>
					</Link>
					<Link to="/music" className="kk-btn btn-wide a-center">
						<MusicButton className="nav-button"/>
					</Link>

				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
			<Sponsor/>
		</div>
		{/* / .game-box */}

	</Page>;
}

export const query = graphql`
    query {
		global {
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}
			}
		}
		images {
			octopus {
				localFile {
					publicURL
				}
			}
		}
    }
`;
